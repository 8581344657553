<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title" @click="backClick">{{ $t('myAd') }}</div>
				<div class="front_img">
					<img src="@/assets/home_icons/front.png">
				</div>
				<div class="sub_title" style="font-size:18px">{{ $t('order.applyAd') }}</div>
			</div>
			<div class="all_list">
				<div class="list_item">
					<div class="list_item_top">
						<!-- <span>*</span> -->
						{{ $t('order.adPosition') }}
					</div>
					<div class="list_item_bottom">
						<el-select v-model="ads_type_id" :placeholder="$t('order.pleaseSelectAdPosition')" clearable
							@change="selectChange" ref="select">
							<el-option v-for="item in positionList" :key="item.value" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="list_item">
					<div class="list_item_top">
						<span></span>{{ $t('order.adContent') }}
					</div>
					<div class="list_item_bottom">
						<el-select v-model="information_id" :placeholder="$t('order.pleaseSelectAdContent')" clearable
							v-loadmore="loadMore">
							<el-option v-for="item in myAdContentList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="list_item">
					<div class="list_item_top">
						<!-- <span>*</span> -->
						{{ $t('order.adDuration') }}
					</div>
					<div class="list_item_bottom">
						<el-input :placeholder="$t('order.pleaseSelectAdDuration')" type="number" v-model="num" clearable>
						</el-input>
					</div>
				</div>
				<div class="list_item" style="border-bottom: none;">
					<div class="list_item_top">
						<!-- <span>*</span> -->
						{{ $t('order.photo') }}({{ $t('order.aSheet') }})
					</div>
					<div class="list_item_bottom">
						<div class="all_imgs">
							<el-upload ref="uploadImg" :action="uploadImgUrl" list-type="picture-card"
								:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :limit="1"
								:before-upload="beforeUpload" :on-change="onChange" :multiple="true">
								<div slot="tip" class="el-upload__tip">{{ $t('atMostPhoto') }}</div>
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="previewImgDialog" :modal="false">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</div>
					</div>
				</div>
				<div class="btn" @click="btnClick">
					<div>{{ $t('order.toPay') }}(${{ adPrice * Number(num) + (adPrice * Number(num) * Number(pay_tax) / 100)
					}})</div>
				</div>
			</div>
		</div>


		<!-- 支付选择框 -->
		<div class="pay_dialog">
			<el-dialog title="支付方式" :visible.sync="payVisible">
				<div class="pay_mongy">
					<div>{{ $t('pay.amountPayable') }}：<span class="money">${{ total_money }}</span></div>
					<div class="shui">
						（{{ $t('pay.include') }}{{ pay_tax }}{{ $t('pay.taxRate') }}）
					</div>
				</div>

				<div class="change_pay">
					<div class="pay_box" v-for="(item, index) in payList" :key="index" @click="choosePay(item, index)">
						<div class="left">
							<img :src="item.icon" alt="">
							<div>{{ item.pay_type }}</div>
						</div>
						<div class="right" >
							<img src="@/assets/mycenter/is_ok.png" v-if="active == index">
							<img src="@/assets/mycenter/no_ok.png" v-else>
						</div>
					</div>
				</div>

				<div class="btn_pay">
					<button @click="pay_btn">
						{{ $t('pay.payNow') }}
					</button>
				</div>
			</el-dialog>
		</div>

	</div>
</template>

<script>
import {
	uploadImgUrl
} from '@/common/utils/config.js';

export default {
	data() {
		return {
			uploadImgUrl: uploadImgUrl,
			dialogImageUrl: '', //预览图片地址
			img_list: [], //上传图片列表
			previewImgDialog: false,

			num: '', //广告天数
			positionList: [], //广告位置列表
			ads_type_id: '', //广告位id
			adPrice: 0, //广告价格

			information_id: '', //

			page: 1,
			pageSize: 10,
			total: '',
			myAdContentList: [], //所有广告内容

			payList: [],//支付方式列表
			pay_tax: '', //税率
			total_money: '',//总价
			active: '0',
			pay_type: 'Yappy',
			order_sn: '', // 支付订单

			payVisible: false,

		}
	},
	mounted() {
		this.get_ads_list();
		this.getAllAd();
		this.getPayFn()
	},
	methods: {
		// 所选广告内容下拉加载更多
		loadMore() {
			if (this.myAdContentList.length < this.total) {
				this.page++;
			}
		},
		getAllAd() {
			let obj = {
				page: this.page,
				pageSize: this.pageSize,
				check: 2,
			}
			this.$http.msgList(obj).then(res => {
				if (res.code == 1) {
					this.myAdContentList = res.data.data;
					this.total = res.data.total;
					console.log("-0999任", this.myAdContentList);
				} else {

				}
			})
		},
		// 下拉框筛选事件
		selectChange(e) {
			console.log(e);
			this.pay_tax = this.positionList.filter(item => item.id == e)[0].pay_tax
			this.adPrice = this.positionList.filter(item => item.id == e)[0].price;
			console.log(this.pay_tax, this.adPrice);
		},
		// 获取广告位置
		get_ads_list() {
			this.$http.get_ads_list().then(res => {
				if (res.code == 1) {
					console.log();
					this.positionList = res.data;
				} else {
					this.$message.info(res.msg);
				}
			})
		},

		backClick() {
			this.$router.back();
		},
		// 图片删除
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.img_list = [];
		},
		// 图片预览
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.previewImgDialog = true;
		},
		// 上传之前
		beforeUpload(file) {
			const fileName = file.name
			const fileType = fileName.substring(fileName.lastIndexOf('.'));
			if (
				fileType === '.jpg' ||
				fileType === '.png' ||
				fileType === '.jpeg' ||
				fileType === '.bmp' ||
				fileType === '.gif'
			) {

			} else {
				this.$message.error(this.$t('pleaseUploadTheCorrectImageType'));
				return false;
			}
		},
		// 上传图片成功
		onChange(file, fileList) {
			console.log(file);
			console.log(fileList);
			this.img_list = fileList;
		},

		// 去支付
		btnClick() {
			this.total_money =  this.adPrice * Number(this.num) + (this.adPrice * Number(this.num) * Number(this.pay_tax) / 100)
			console.log(this.img_list);
			if (this.isFlag) return;
			let image = this.img_list.map(item => item.response.data[0].id).join(",");
			if (!this.ads_type_id) {
				this.$message.error(this.$t('order.pleaseSelectAdPosition'));
				return;
			}
			// if (!this.information_id) {
			// 	this.$message.error(this.$t('order.pleaseSelectAdContent'));
			// 	return;
			// }
			if (!this.num) {
				this.$message.error(this.$t('order.pleaseSelectAdDuration'));
				return;
			}
			if (!image) {
				this.$message.error(this.$t('tips.atLeastOnePhoto'));
				return;
			}

			this.isFlag = true;
			let data = {
				ads_type_id: this.ads_type_id,
				num: this.num,
				payable_money: this.adPrice * Number(this.num),
				pay_tax:this.adPrice * Number(this.num)* Number(this.pay_tax) / 100,
				image,
				information_id: this.information_id,
			};
			this.$http.add_ads_order(data).then(res => {
				console.log(9999999999);
				console.log(res);
				if (res.code == 1) {
					this.isFlag = false;
					// this.paypalPay(res.data.order_sn);
					this.order_sn = res.data.order_sn
					this.payVisible = true // 打开支付弹窗

				} else {
					this.isFlag = false;
					this.$message.error(res.msg);
				}
			})
		},


		// 获取支付方式
		getPayFn() {
			this.$http.changePay().then(res => {
				if (res.code == 1) {
					this.payList = res.data
				} else {
					this.$message.info(res.data.msg)
				}
			})
		},
		// 选择支付方式
		choosePay(item, index) {
			console.log(item);
			this.pay_type = item.pay_type
			this.active = index
		},

		pay_btn() {
			console.log(this.pay_type,1234);
			if (this.pay_type == 'Paypal') {
				// Paypal
				this.paypalPay(this.order_sn)
			} else if (this.pay_type == 'Yappy') {
				// Yappy
				this.yaPayPay(this.order_sn)
			} else {
				//余额
				this.yuePay(this.order_sn)
			}
		},
		// paypal支付
		paypalPay(orderSn) {
			this.$http.getPayPal({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					let payUrl = res.data;
					window.open(payUrl);
					this.payVisible = false // 打开支付弹窗
					this.$message.success('支付成功')
					setTimeout(() => {
						this.$router.push({
							path: '/adOrder'
						})
						this.clearData();
					}, 2000);
				}else{
					this.$message.error(res.msg)
				}
			})
		},

		// yapay支付 646b400bad167
		yaPayPay(orderSn) {
			this.$http.getYaPay({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.payVisible = false
					let payUrl = res.data;
					console.log(payUrl, '11233415s');
					setTimeout(() => {
						this.$router.push({
							path: '/adOrder'
						})
						this.clearData();
					}, 3000);
					window.open(payUrl);
				}else{
					this.$message.error(res.msg)
				}
			})
		},

		// 余额支付 5e489f45a051d
		yuePay(orderSn) {
			this.$http.getYuePay({
				order_sn: orderSn,
			}).then(res => {
				if (res.code == 1) {
					this.payVisible = false
					setTimeout(() => {
						this.$router.push({
							path: '/adOrder'
						})
						this.clearData();
					}, 3000);
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		// 清空数据
		clearData() {
			this.ads_type_id = '';
			this.num = '';
			this.information_id = '';
			this.$refs.uploadImg.clearFiles();
		}
	}
}
</script>

<style scoped="" lang="less">
.person_infor {
	width: 926px;
}

/deep/ input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}

.login_top {
	display: flex;
	align-items: center;
	padding: 24px 40px;
	border-bottom: 1px solid #F3F5F6;


	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
		color:#F39800;

		&:hover {
			cursor: pointer;
		}
	}

	.front_img {
		display: flex;
		align-items: center;
		margin: 0 12px;

		img {
			width: 8px;
			height: 14px;
		}
	}
}

.all_list {
	margin: 0 50px;
	padding-top: 24px;
}

.list_item {
	text-align: left;
	padding: 24px 0;
	border-bottom: 1px solid #F3F5F6;
	font-size: 16px;

	&:last-child {
		border-bottom: none;
	}

	.list_item_top {
		span {
			color: #F39800;
		}
	}

	.list_item_bottom {
		font-size: 14px;
		margin-top: 28px;
		color: #4177DD;


		/deep/ .el-input__inner {
			width: 800px;
			border: none !important;
		}
	}
}

.btn {
	margin: 70px 0 40px;
	text-align: left;
	cursor: pointer;

	div {
		text-align: center;
		display: inline-block;
		min-width: 280px;
		padding: 14px 0;
		background: #F39800;
		opacity: 1;
		border-radius: 24px;
		font-size: 16px;
		color: #FFFFFF;
	}

}


.pay_dialog {
	/deep/ .el-dialog {
		width: 480px !important;

	}

	/deep/ .el-dialog__body {
		max-height: 480px;
		overflow-y: hidden;
	}

	/deep/ .el-dialog__header {
		text-align: left;
		padding: 24px 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		border-bottom: 1px solid #DDDDDD;
	}

	.pay_mongy {
		height: 142px;
		border-bottom: 1px solid #DDDDDD;

		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;

		.money {
			font-size: 32px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #E60012;
		}

		.shui {
			margin-top: 12px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
		}

	}

	.change_pay {
		border-bottom: 1px solid #DDDDDD;

		.pay_box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 32px;

			.left {
				display: flex;
				justify-content: left;
				align-items: center;


				img {
					width: 40px;
					height: 40px;
				}

				div {
					margin-left: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
			}

			.right {
				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.pay_box:last-child {
			margin-bottom: 32px;
		}
	}

	.btn_pay {
		padding-top: 24px;

		button {
			width: 300px;
			height: 40px;
			background: rgba(243, 152, 0);
			border-radius: 4px;
			border: none;
			color: #fff;
			font-size: 14px;
		}
	}
}

</style>
